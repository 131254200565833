import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import 'moment-timezone';
import { FlexGrid } from '../../../components/FlexGrid';
import {
    Table,
    TableCell,
    TableHead,
    // TablePagination,
    TableRow,
} from '../../../components/Table';
import { Text } from '../../../components/Text';
import { COLORS } from '../../../styles/variables';
import { ArrowDownIcon, BonusPointsIcon } from '../../../assets/icons';
import { Button } from '../../../components/Button';
import { Modal } from '../../../components/Modal';
import { IconButton } from '../../../components/IconButton';
import { Pagination } from '../../../components/Pagination';
import { ContentfulApiService } from '../../../services/ContentfulApi.service';
import {
    RegistrationContestItemToEditModel,
    getCountry,
    getDefaultTimezone,
    getUrlKeepQuery,
    setCountry,
} from '../../../utils';
import { sortFunc } from '../../../common';
import AccountMultipleUsers from '../../../assets/icons/account-multiple-users';
import { useRegisterContestHook } from '../hooks/use-register-contest-hook';
import { ApiService } from '../../../services/Api.service';
import { RadioGroup } from '../../../components/RadioGroup';

const TableActionBtn = styled(Button)`
    text-transform: none;
    font-family: 'AR';
    border-radius: 0;
    margin: 0;
    border-left: 1px solid ${COLORS.lightGray};

    &:first-of-type {
        border-left: none;
    }
`;

export const RegistrationContestsTable: React.FC = () => {
    const navigate = useNavigate();
    const [current, setCurrent] = useState(null);
    const [update, setUpdate] = useState(false);
    const [loading, setLoading] = useState(false);
    const [radioCountry, setRadioCountry] = useState('Ireland/UK');
    const perPage = 5;
    const DEFAULT_SORT = {
        name: 0,
        startDate: 0,
        endDate: 0,
        pointsForAchievement: 0,
        targetedUsers: 0,
    };
    const [sort, setSort] = useState<any>(DEFAULT_SORT);

    const location = useLocation() as any;
    const [page, setPage] = useState(location.state?.page || 1);

    const [{ isLoading, hookData: registerContestObj }] =
        useRegisterContestHook({}, [update]);

    const [deleteModal, setDeleteModal] = useState(false);
    let registerContest = sortFunc(sort, registerContestObj.mapped ?? []);
    const total = registerContestObj.raw?.total || 0;
    const startIndex = (page - 1) * perPage;
    const endIndex = startIndex + perPage;
    registerContest = registerContest.slice(startIndex, endIndex);
    const isIrealandUk =
        getCountry() === 'Ireland' || getCountry() === 'Ireland/UK';
    const [registerContestsDashboardStats, setRegisterContestsDashboardStats] =
        useState({
            pointsDisbursed: 0,
            scanningContestsActive: 0,
            scanningContestsExpired: 0,
            totalParticipants: 0,
            totalContestLaunched: total,
        });
    useEffect(() => {
        if (!isLoading) {
            const today = new Date();
            const active = registerContest.filter(
                (data) => new Date(data.endDate._i) >= today
            );
            if (isIrealandUk) {
                setCountry(radioCountry);
            }
            ApiService.getRegisterContestsDashboardStats()
                .then((response) => {
                    const { data } = response;
                    setRegisterContestsDashboardStats({
                        ...data,
                        scanningContestsActive: active.length,
                        scanningContestsExpired: total - active.length,
                        totalContestLaunched: total,
                    });
                })
                .catch((err) =>
                    console.error(
                        '===Failed to fetch scanning contests dashboard stats===',
                        err
                    )
                );
        }
    }, [isLoading, radioCountry]);
    useEffect(() => {
        setUpdate(!update);
    }, [radioCountry]);

    const itemToEditModel = async (registerContest) => {
        return await RegistrationContestItemToEditModel(registerContest);
    };

    return (
        <>
            {!isLoading && (
                <>
                    {isIrealandUk && (
                        <FlexGrid
                            width="100%"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <RadioGroup
                                options={[
                                    {
                                        label: 'UK',
                                        value: 'Ireland/UK',
                                    },
                                    { label: 'Republic of Ireland', value: 'Ireland' },
                                ]}
                                onChange={(val) => {
                                    setRadioCountry(val);
                                }}
                                value={radioCountry}
                            />
                        </FlexGrid>
                    )}

                    <Modal
                        open={deleteModal}
                        header="Delete Registration Contest"
                        onClose={() => setDeleteModal(false)}
                        content={
                            <FlexGrid
                                direction="column"
                                width="100%"
                                gap="2em"
                                p="2em 0em"
                            >
                                <Text color={COLORS.darkGray}>
                                    Are you sure you want to delete this
                                    RegistrationContest?
                                </Text>
                                <Text color={COLORS.darkGray}>
                                    <Text
                                        color={COLORS.darkGray}
                                        span
                                        font="AR Bold"
                                    >
                                        Note:{' '}
                                    </Text>
                                    Deleting a Registration contest is a
                                    permanent permanent action and cannot be
                                    undone.
                                </Text>
                            </FlexGrid>
                        }
                        actions={
                            <FlexGrid alignItems="center">
                                <Button
                                    onClick={() => {
                                        ContentfulApiService.deleteContent(
                                            current.id
                                        )
                                            .then(() => {
                                                setUpdate(!update);
                                                if (total % perPage === 1) {
                                                    setPage(
                                                        Math.max(page - 1, 1)
                                                    );
                                                }
                                            })
                                            .catch(() => {
                                                alert(
                                                    'Cannot delete published'
                                                );
                                            });
                                        setDeleteModal(false);
                                    }}
                                    p="1.3em 2.5em"
                                >
                                    DELETE
                                </Button>
                                <Button
                                    onClick={() => setDeleteModal(false)}
                                    negative
                                    p="0.5em 1em"
                                >
                                    CANCEL
                                </Button>
                            </FlexGrid>
                        }
                    />
                    <Table>
                        <TableRow>
                            <TableHead
                                padding="2em 0em 0em 0em"
                                borderBottomDisabled
                            >
                                <FlexGrid alignItems="center" gap="0.8em">
                                    Total Contests
                                </FlexGrid>
                            </TableHead>
                            <TableHead
                                padding="2em 0em 0em 0em"
                                borderBottomDisabled
                            >
                                <FlexGrid alignItems="center" gap="0.8em">
                                    Contests Active
                                </FlexGrid>
                            </TableHead>
                            <TableHead
                                padding="2em 0em 0em 0em"
                                borderBottomDisabled
                            >
                                <FlexGrid alignItems="center" gap="0.8em">
                                    Contests Expired
                                </FlexGrid>
                            </TableHead>
                            <TableHead
                                padding="2em 0em 0em 0em"
                                borderBottomDisabled
                            >
                                <FlexGrid alignItems="center" gap="0.8em">
                                    Total Participants
                                </FlexGrid>
                            </TableHead>
                            <TableHead
                                padding="2em 0em 0em 0em"
                                borderBottomDisabled
                            >
                                <FlexGrid alignItems="center" gap="0.8em">
                                    Total Points Disbursed
                                </FlexGrid>
                            </TableHead>
                        </TableRow>
                        <TableRow>
                            <TableCell
                                padding="0em 0em 0em 0em"
                                borderBottomDisabled
                                verticalAlign="top"
                                width="250px"
                            >
                                <Text
                                    font="OS"
                                    fontSize="1.5rem"
                                    color={COLORS.black}
                                >
                                    {
                                        registerContestsDashboardStats.totalContestLaunched
                                    }
                                </Text>
                            </TableCell>
                            <TableCell
                                padding="0em 0em 0em 0em"
                                borderBottomDisabled
                                verticalAlign="top"
                                width="250px"
                            >
                                <Text
                                    font="OS"
                                    fontSize="1.5rem"
                                    color={COLORS.black}
                                >
                                    {
                                        registerContestsDashboardStats.scanningContestsActive
                                    }
                                </Text>
                            </TableCell>
                            <TableCell
                                padding="0em 0em 0em 0em"
                                borderBottomDisabled
                                verticalAlign="top"
                                width="250px"
                            >
                                <Text
                                    font="OS"
                                    fontSize="1.5rem"
                                    color={COLORS.black}
                                >
                                    {
                                        registerContestsDashboardStats.scanningContestsExpired
                                    }
                                </Text>
                            </TableCell>
                            <TableCell
                                padding="0em 0em 0em 0em"
                                borderBottomDisabled
                                verticalAlign="top"
                                width="250px"
                            >
                                <Text
                                    font="OS"
                                    fontSize="1.5rem"
                                    color={COLORS.black}
                                >
                                    {
                                        registerContestsDashboardStats.totalParticipants
                                    }
                                </Text>
                            </TableCell>
                            <TableCell
                                padding="0em 0em 0em 0em"
                                borderBottomDisabled
                                verticalAlign="top"
                                width="250px"
                            >
                                <Text
                                    font="OS"
                                    fontSize="1.5rem"
                                    color={COLORS.black}
                                >
                                    {`${registerContestsDashboardStats.pointsDisbursed} pts`}
                                </Text>
                            </TableCell>
                        </TableRow>
                    </Table>
                    <Table>
                        <TableRow>
                            <TableHead>
                                <FlexGrid alignItems="center" gap="0.8em">
                                    Contest Name
                                    {sort.name === 0 && (
                                        <IconButton
                                            negative
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    name: 1,
                                                });
                                            }}
                                            transform="rotate(-90deg)"
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                    {sort.name === 1 && (
                                        <IconButton
                                            negative
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    name: -1,
                                                });
                                            }}
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                    {sort.name === -1 && (
                                        <IconButton
                                            negative
                                            transform="rotate(180deg)"
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    name: 0,
                                                });
                                            }}
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                </FlexGrid>
                            </TableHead>
                            <TableHead>Headline</TableHead>
                            <TableHead>
                                <FlexGrid alignItems="center" gap="0.8em">
                                    Start Date
                                    {sort.startDate === 0 && (
                                        <IconButton
                                            negative
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    startDate: 1,
                                                });
                                            }}
                                            transform="rotate(-90deg)"
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                    {sort.startDate === 1 && (
                                        <IconButton
                                            negative
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    startDate: -1,
                                                });
                                            }}
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                    {sort.startDate === -1 && (
                                        <IconButton
                                            negative
                                            transform="rotate(180deg)"
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    startDate: 0,
                                                });
                                            }}
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                </FlexGrid>
                            </TableHead>
                            <TableHead>
                                <FlexGrid alignItems="center" gap="0.8em">
                                    End Date
                                    {sort.endDate === 0 && (
                                        <IconButton
                                            negative
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    endDate: 1,
                                                });
                                            }}
                                            transform="rotate(-90deg)"
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                    {sort.endDate === 1 && (
                                        <IconButton
                                            negative
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    endDate: -1,
                                                });
                                            }}
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                    {sort.endDate === -1 && (
                                        <IconButton
                                            negative
                                            transform="rotate(180deg)"
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    endDate: 0,
                                                });
                                            }}
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                </FlexGrid>
                            </TableHead>
                            <TableHead>
                                <FlexGrid alignItems="center" gap="0.8em">
                                    Awarded Points
                                    {sort.pointsForAchievement === 0 && (
                                        <IconButton
                                            negative
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    pointsForAchievement: 1,
                                                });
                                            }}
                                            transform="rotate(-90deg)"
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                    {sort.pointsForAchievement === 1 && (
                                        <IconButton
                                            negative
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    pointsForAchievement: -1,
                                                });
                                            }}
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                    {sort.pointsForAchievement === -1 && (
                                        <IconButton
                                            negative
                                            transform="rotate(180deg)"
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    pointsForAchievement: 0,
                                                });
                                            }}
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                </FlexGrid>
                            </TableHead>
                            <TableHead>
                                <FlexGrid alignItems="center" gap="0.8em">
                                    Targed Users
                                    {sort.targetedUsers === 0 && (
                                        <IconButton
                                            negative
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    targetedUsers: 1,
                                                });
                                            }}
                                            transform="rotate(-90deg)"
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                    {sort.targetedUsers === 1 && (
                                        <IconButton
                                            negative
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    targetedUsers: -1,
                                                });
                                            }}
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                    {sort.targetedUsers === -1 && (
                                        <IconButton
                                            negative
                                            transform="rotate(180deg)"
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    targetedUsers: 0,
                                                });
                                            }}
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                </FlexGrid>
                            </TableHead>
                            <TableHead>Reached Users</TableHead>
                            <TableHead>Points Disbursed</TableHead>
                            <TableHead>Actions</TableHead>
                        </TableRow>
                        {registerContest.map((registerContest, key) => {
                            return (
                                <TableRow key={key}>
                                    <TableCell>
                                        <Text
                                            link
                                            fontSize="0.875rem"
                                            color={COLORS.green}
                                            onClick={() => {
                                                navigate(
                                                    getUrlKeepQuery(
                                                        `/registerContest/${registerContest.id}`
                                                    )
                                                );
                                            }}
                                        >
                                            {registerContest?.name}
                                        </Text>
                                    </TableCell>
                                    <TableCell>
                                        {registerContest?.headline}
                                    </TableCell>
                                    <TableCell>
                                        <FlexGrid direction="column">
                                            <Text fontSize="0.875rem">
                                                {moment
                                                    .tz(
                                                        registerContest?.startDate,
                                                        'DD MMMM YYYY',
                                                        getDefaultTimezone()
                                                    )
                                                    .format('MMM DD, YYYY')}
                                            </Text>
                                            <Text fontSize="0.875rem">
                                                {registerContest?.status}
                                            </Text>
                                        </FlexGrid>
                                    </TableCell>
                                    <TableCell>
                                        <FlexGrid direction="column">
                                            <Text fontSize="0.875rem">
                                                {moment
                                                    .tz(
                                                        registerContest?.endDate,
                                                        'DD MMMM YYYY',
                                                        getDefaultTimezone()
                                                    )
                                                    .format('MMM DD, YYYY')}
                                            </Text>
                                            <Text fontSize="0.875rem">
                                                {registerContest?.status}
                                            </Text>
                                        </FlexGrid>
                                    </TableCell>
                                    <TableCell>
                                        <FlexGrid gap="1em" alignItems="center">
                                            <BonusPointsIcon />
                                            <Text fontSize="0.875rem">
                                                {`${
                                                    registerContest?.pointsForAchievement ??
                                                    0
                                                }`}
                                            </Text>
                                        </FlexGrid>
                                    </TableCell>
                                    <TableCell>
                                        <FlexGrid gap="1em" alignItems="center">
                                            <AccountMultipleUsers />
                                            <Text fontSize="0.875rem">
                                                {`${
                                                    registerContest?.targetedUsers ??
                                                    0
                                                }`}
                                            </Text>
                                        </FlexGrid>
                                    </TableCell>
                                    <TableCell>
                                        <FlexGrid gap="1em" alignItems="center">
                                            <AccountMultipleUsers />
                                            <Text fontSize="0.875rem">
                                                {`${
                                                    registerContest?.targetedUsers -
                                                        registerContest?.reachedUsers ??
                                                    0
                                                }`}
                                            </Text>
                                        </FlexGrid>
                                    </TableCell>
                                    <TableCell>
                                        <FlexGrid gap="1em" alignItems="center">
                                            <BonusPointsIcon />
                                            <Text fontSize="0.875rem">
                                                {`${
                                                    (registerContest?.targetedUsers -
                                                        registerContest?.reachedUsers ??
                                                        0) *
                                                        registerContest?.pointsForAchievement ??
                                                    0
                                                }`}
                                            </Text>
                                        </FlexGrid>
                                    </TableCell>
                                    <TableCell>
                                        <FlexGrid alignItems="center">
                                            {/* <TableActionBtn
                                                onClick={async () => {
                                                    setCurrent(registerContest);
                                                    setDetailsModal(true);
                                                }}
                                                negative
                                            >
                                                Stats
                                            </TableActionBtn> */}
                                            <TableActionBtn
                                                onClick={async () => {
                                                    if (loading) {
                                                        return;
                                                    }
                                                    setLoading(true);
                                                    navigate(
                                                        getUrlKeepQuery(
                                                            '/scheme-offers/registration-contests/create'
                                                        ),
                                                        {
                                                            state: {
                                                                data: await itemToEditModel(
                                                                    registerContest
                                                                ),
                                                                page,
                                                                viewOnly: true,
                                                            },
                                                        }
                                                    );
                                                }}
                                                negative
                                            >
                                                View
                                            </TableActionBtn>
                                            <TableActionBtn
                                                onClick={async () => {
                                                    if (loading) {
                                                        return;
                                                    }
                                                    setLoading(true);
                                                    navigate(
                                                        getUrlKeepQuery(
                                                            '/scheme-offers/registration-contests/create'
                                                        ),
                                                        {
                                                            state: {
                                                                data: await itemToEditModel(
                                                                    registerContest
                                                                ),
                                                                page,
                                                            },
                                                        }
                                                    );
                                                }}
                                                negative
                                            >
                                                Edit
                                            </TableActionBtn>
                                            <TableActionBtn
                                                onClick={() => {
                                                    setCurrent(registerContest);
                                                    setDeleteModal(true);
                                                }}
                                                negative
                                            >
                                                Remove
                                            </TableActionBtn>
                                        </FlexGrid>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </Table>
                    <Pagination
                        page={page}
                        perPage={perPage}
                        total={total}
                        setPage={(v) => {
                            setPage(v);
                        }}
                    />
                </>
            )}
        </>
    );
};
