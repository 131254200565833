import { ACTIONS } from './store.actions';
import { AppAction, AppState, User } from './store.types';

export const initState: AppState = {
    user: {} as User,
    country: '',
    language: '',
    contentfulToken: '',
    modal: { open: false, title: '', content: '' },
    loadingModal: { open: false, title: '', progress: 0, content: '' }
};

const appReducer = (
    // eslint-disable-next-line default-param-last
    state: AppState = initState,
    action: AppAction<any>
): AppState => {
    switch (action.type) {
        case ACTIONS.ADD_USER: {
            return {
                ...state,
                user: action.payload,
            };
        }
        case ACTIONS.SET_COUNTRY: {
            return {
                ...state,
                country: action.payload,
            };
        }
        case ACTIONS.SET_LANGUAGE: {
            return {
                ...state,
                language: action.payload,
            };
        }
        case ACTIONS.SET_CONTENTFUL_TOKEN: {
            return {
                ...state,
                contentfulToken: action.payload,
            };
        }
        case ACTIONS.SET_MODAL: {
            return {
                ...state,
                modal: {
                    ...state.modal,
                    noAction: false,
                    ...action.payload,
                },
            };
        }
        case ACTIONS.SET_LOADING_MODAL: {
            return {
                ...state,
                loadingModal: {
                    ...state.modal,
                    ...action.payload,
                },
            };
        }
        default:
            return state;
    }
};

export default appReducer;
