import _ from 'lodash';
import { ContentfulClientApi } from 'contentful';
import * as contentfulManage from 'contentful-management';
import { ClientAPI } from 'contentful-management';
import {
    // axiosRequest,
    contentfulApiRequest,
    rrlSchedule,
} from '../utils/http-client';

import { GLOBAL_CONFIG } from '../config/config';

import {
    DEFAULT_LANGUAGE_CODE,
    LANGUAGE_CONTENTFUL_MAP,
    // ROLE_TAG_ID_MAP,
} from '../common/constants';
import { cfDefaultGet, getMetaTag } from '../utils';

// import * as uuid from 'uuid';
// import contentful from "contentful" will use .node.js file, and will throw error, is there a workround(e.g. config in package.json)
// to automatically transform contentful to contentful/dist/es-modules/contentful ?
const contentful = require('contentful/dist/es-modules/contentful');

const host = GLOBAL_CONFIG.CONTENTFUL_API_PATH.split('://')[1];

class Client {
    contentfulClient: ContentfulClientApi;

    contentfulManageClient: ClientAPI;

    get client() {
        if (!this.contentfulClient) {
            this.contentfulClient = contentful.createClient({
                space: GLOBAL_CONFIG.CONTENTFUL_SPACE_ID,
                accessToken: sessionStorage.getItem('contentfulToken'),
                environment: GLOBAL_CONFIG.environment,
                host,
            });
        }
        return this.contentfulClient;
    }

    get manageClient() {
        return contentfulManage.createClient({
            // This is the access token for this space. Normally you get the token in the Contentful web app
            // space: GLOBAL_CONFIG.CONTENTFUL_SPACE_ID,
            accessToken: sessionStorage.getItem('contentfulToken'),
            // accessToken: 'CFPAT-ojs-hZOt7jtiUFVzQHYW296DByQ_QMaW-msMAfipoT0',
            host,
        });
    }
}

const client = new Client();

export type ContentFulType =
    | 'banner'
    | 'video'
    | 'article'
    | 'videoPlaylist'
    | 'articleFolder'
    | 'notification'
    | 'pushNotification'
    | 'inAppNotification'
    | 'quiz'
    | 'quizQuestion'
    | 'scanningContest'
    | 'scanningContestScheme'
    | 'registrationContest'
    | 'learningCardTheme'
    | 'learningCardSection'
    | 'learningCard'
    | 'learningCardSlide';

export class ContentfulApiService {
    /**
     * Search Content from Contentful API
     *
     * @param contentType content type
     * @param limit limit (default to 3)
     * @param page page (default to 0)
     */
    static async searchContent({
        contentType,
        query = null,
        limit,
        orderByCreated,
        sortBy,
        page = 1,
        params: inputParams = {},
    }: {
        query?: string;
        contentType?: ContentFulType;
        limit?: number;
        orderByCreated?: boolean;
        sortBy?: string;
        page?: number;
        params?: any;
    }): Promise<any> {
        let params: any = {
            ...inputParams,
            'metadata.tags.sys.id[in]': getMetaTag(),
            // reference contentful relational queries
            // https://www.contentful.com/developers/docs/android/tutorials/advanced-filtering-and-searching/
            'sys.archivedVersion[exists]': 'false',
        };
        if (contentType) {
            params = {
                ...params,
                content_type: contentType,
            };
        }

        if (query !== null) {
            params.query = query;
        }

        if (limit) {
            params = {
                ...params,
                limit,
                skip: (page - 1) * Number(limit),
            };
        }

        if (orderByCreated) {
            params = {
                ...params,
                order: '-sys.createdAt',
            };
        }

        if (sortBy) {
            params = {
                ...params,
                order: sortBy,
            };
        }

        await rrlSchedule();

        return client.client.getEntries(params);
    }

    static async deleteContent(entryId: string) {
        await rrlSchedule();

        await client.manageClient
            .getSpace(GLOBAL_CONFIG.CONTENTFUL_SPACE_ID)
            .then((space) => space.getEnvironment(GLOBAL_CONFIG.environment))
            .then((environment) => environment.getEntry(entryId))
            .then((entry) => {
                if (entry.isPublished()) {
                    entry.unpublish();
                }
            });

        const deleted = await client.manageClient
            .getSpace(GLOBAL_CONFIG.CONTENTFUL_SPACE_ID)
            .then((space) => space.getEnvironment(GLOBAL_CONFIG.environment))
            .then((environment) => environment.deleteEntry(entryId));

        console.log(`deleted entry ${entryId}`, deleted);
    }

    static async createUpload(fl: File, file: ArrayBuffer) {
        // another method
        // environment.createAssetFromFiles, but how to set tag by using this method ?

        await rrlSchedule();

        const tags = await client.manageClient
            .getSpace(GLOBAL_CONFIG.CONTENTFUL_SPACE_ID)
            .then((space) => space.getEnvironment(GLOBAL_CONFIG.environment))
            .then((environment) => {
                return environment.getTags();
            });
        console.log('tags', tags, getMetaTag());

        const tag = tags.items.find(
            (tag) => _.upperCase(tag.sys.id) === _.upperCase(getMetaTag())
        );
        if (!tag) {
            tags.items.find(
                (tag) => _.upperCase(tag.name) === _.upperCase(getMetaTag())
            );
        }

        // upload response(first api) example
        // {
        //     "sys": {
        //         "id": "7oL5cxUucKMR8xb4HNlWTi",
        //         "createdAt": "2022-03-28T10:58:59.000Z",
        //         "expiresAt": "2022-03-30T00:00:00.000Z",
        //         "createdBy": {
        //             "sys": {
        //                 "type": "Link",
        //                 "linkType": "User",
        //                 "id": "3uzN7wr60HzLmz1PFgvQZd"
        //             }
        //         },
        //         "type": "Upload",
        //         "space": {
        //             "sys": {
        //                 "type": "Link",
        //                 "linkType": "Space",
        //                 "id": "4wfl0fpp9t0j"
        //             }
        //         }
        //     }
        // }

        const upload = await client.manageClient
            .getSpace(GLOBAL_CONFIG.CONTENTFUL_SPACE_ID)
            .then((space) => space.getEnvironment(GLOBAL_CONFIG.environment))
            .then((env) =>
                env.createUpload({
                    file,
                })
            )
            .then((ud) => {
                return ud;
            });

        console.log('uploaded', upload);

        // // publish
        const asset = await client.manageClient
            .getSpace(GLOBAL_CONFIG.CONTENTFUL_SPACE_ID)
            .then((space) => space.getEnvironment(GLOBAL_CONFIG.environment))
            .then((env) =>
                env.createAsset({
                    metadata: {
                        tags: [
                            {
                                sys: {
                                    type: 'Link',
                                    linkType: 'Tag',
                                    id: tag.sys.id,
                                },
                            },
                        ],
                    },
                    fields: {
                        title: {
                            'en-US': fl.name,
                        },
                        file: {
                            'en-US': {
                                fileName: fl.name,
                                contentType: fl.type,
                                uploadFrom: {
                                    sys: {
                                        id: upload.sys.id,
                                        type: 'Link',
                                        linkType: 'Upload',
                                    },
                                },
                            },
                        },
                    },
                })
            )
            .then((asset) => {
                console.log('processForAllLocales...');
                // return asset.processForAllLocales({processingCheckWait: 2000})
                return asset.processForAllLocales();
            })
            .then((asset) => {
                console.log('publish...');
                return asset.publish();
            })
            .then((asset) => {
                console.log('published', asset);
                return asset;
            })
            .catch(console.error);

        return asset;
    }

    /**
     * Update Content from Contentful API
     *
     * @param entryId entryId
     * @param content content to update
     * @param fields fields to update
     */
    static async updateContentFields(
        entryId,
        content,
        fields,
        languageFields = {},
        omit = [],
        options?: any
    ) {
        const params = {
            'metadata.tags.sys.id[in]': getMetaTag(),
        };
        const languages =
            cfDefaultGet(content?.fields, 'language') &&
            _.map(cfDefaultGet(content?.fields, 'language'), (v, k) => v);

        const lanFields = {};
        _.forEach(fields, (v, k) => {
            lanFields[k] = {
                [DEFAULT_LANGUAGE_CODE]: v,
            };
        });
        _.forEach(languageFields, (lv, k) => {
            if (Array.isArray(lv)) {
                lanFields[k] = {} as any;
                _.forEach(lv, (elem, idx) => {
                    // lanFields[k][idx] = {} as any;
                    let enUsSet = false;
                    _.forEach(elem as object, (v, lk) => {
                        // remove undefined languages
                        if (
                            languages &&
                            !languages.find((llan) => llan === lk)
                        ) {
                            return;
                        }
                        if (!lanFields[k][LANGUAGE_CONTENTFUL_MAP[lk]]) {
                            lanFields[k][LANGUAGE_CONTENTFUL_MAP[lk]] = [];
                        }
                        lanFields[k][LANGUAGE_CONTENTFUL_MAP[lk]][idx] = v;
                        // todo: provide default value for english, after verify with contentful, remove this
                        if (
                            ((v && !enUsSet) || lk === 'English') &&
                            lanFields[k]['en-US']
                        ) {
                            if (!lanFields[k]['en-US']) {
                                lanFields[k]['en-US'] = [];
                            }
                            lanFields[k]['en-US'][idx] = v;
                            enUsSet = true;
                        }
                    });
                });
            } else {
                lanFields[k] = {} as any;
                let enUsSet = false;
                _.forEach(lv as Object, (v, lk) => {
                    // remove undefined languages
                    if (languages && !languages.find((llan) => llan === lk)) {
                        return;
                    }
                    lanFields[k][LANGUAGE_CONTENTFUL_MAP[lk]] = v;
                    // todo: provide default value for english, after verify with contentful, remove this
                    if ((v && !enUsSet) || lk === 'English') {
                        if (!lanFields[k]['en-US']) {
                            lanFields[k]['en-US'] = [];
                        }
                        lanFields[k]['en-US'] = v;
                        enUsSet = true;
                    }
                });
            }
        });
        await rrlSchedule();
        let metadata = {};
        if (options?.tags) {
            metadata = { tags: options?.tags };
        } else {
            metadata = content?.metadata;
        }
        const { data: updated } = await contentfulApiRequest({
            url: `${GLOBAL_CONFIG.CONTENTFUL_API_PATH}/spaces/${GLOBAL_CONFIG.CONTENTFUL_SPACE_ID}/environments/${GLOBAL_CONFIG.environment}/entries/${entryId}`,
            method: 'PUT',
            data: {
                metadata: metadata || '',
                fields: _.omit(
                    {
                        ...content?.fields,
                        ...lanFields,
                    },
                    omit
                ),
            },
            params,
            headers: {
                'X-Contentful-Version': content?.sys?.version || 2,
            },
        });

        console.log('updated', updated);
        return updated;
    }

    /**
     * Create Content from Contentful API
     *
     * @param entryId entryId
     * @param data data to update
     */
    static async createContent(
        contentType: ContentFulType,
        fields,
        languageFields = {},
        options?: any
    ) {
        const params = {
            'metadata.tags.sys.id[in]': getMetaTag(),
        };
        // const entryId = uuid.v4();
        // console.log('uuid v4', entryId, getMetaTag());
        const lanFields = {};

        _.forEach(fields, (v, k) => {
            lanFields[k] = {
                [DEFAULT_LANGUAGE_CODE]: v,
            };
        });

        _.forEach(languageFields, (lv, k) => {
            if (Array.isArray(lv)) {
                lanFields[k] = {} as any;
                _.forEach(lv, (elem, idx) => {
                    // lanFields[k][idx] = {} as any;
                    let enUsSet = false;
                    _.forEach(elem as object, (v, lk) => {
                        if (!lanFields[k][LANGUAGE_CONTENTFUL_MAP[lk]]) {
                            lanFields[k][LANGUAGE_CONTENTFUL_MAP[lk]] = [];
                        }
                        lanFields[k][LANGUAGE_CONTENTFUL_MAP[lk]][idx] = v;
                        // console.log('==k==', k, '=lk=', lk, '==idx==', idx);
                        // console.log('=xxx=lanFields==', lanFields);
                        // todo: provide default value for english, after verify with contentful, remove this
                        if ((v && !enUsSet) || lk === 'English') {
                            if (!lanFields[k]['en-US']) {
                                lanFields[k]['en-US'] = [];
                            }
                            lanFields[k]['en-US'][idx] = v;
                            enUsSet = true;
                        }
                    });
                });
            } else {
                lanFields[k] = {} as any;
                let enUsSet = false;
                _.forEach(lv as object, (v, lk) => {
                    lanFields[k][LANGUAGE_CONTENTFUL_MAP[lk]] = v;
                    // todo: provide default value for english, after verify with contentful, remove this
                    if ((v && !enUsSet) || lk === 'English') {
                        if (!lanFields[k]['en-US']) {
                            lanFields[k]['en-US'] = [];
                        }
                        lanFields[k]['en-US'] = v;
                        enUsSet = true;
                    }
                });
            }
        });

        await rrlSchedule();
        let tags = [];
        if (options?.tags && contentType === 'registrationContest') {
            tags = options?.tags;
        } else {
            tags = [
                {
                    sys: {
                        type: 'Link',
                        linkType: 'Tag',
                        id: getMetaTag(),
                    },
                },
            ];
        }
        // or use manageApi method: environment.createEntry, don't to use environment.createEntryWithId
        const { data: created } = await contentfulApiRequest({
            url: `${GLOBAL_CONFIG.CONTENTFUL_API_PATH}/spaces/${GLOBAL_CONFIG.CONTENTFUL_SPACE_ID}/environments/${GLOBAL_CONFIG.environment}/entries`,
            method: 'POST',
            data: {
                metadata: {
                    tags,
                },
                fields: {
                    ...lanFields,
                },
            },
            params,
            headers: {
                'X-Contentful-Content-Type': contentType,
            },
        });

        console.log('created', contentType, created);

        if (
            options?.publish ||
            contentType === 'videoPlaylist' ||
            contentType === 'articleFolder'
        ) {
            const publishResult = await ContentfulApiService.publish(
                created.sys.id
            );
            console.log('published', publishResult);
        }
        return created;
    }

    static async publish(id: string) {
        const result = await client.manageClient
            .getSpace(GLOBAL_CONFIG.CONTENTFUL_SPACE_ID)
            .then((space) => space.getEnvironment(GLOBAL_CONFIG.environment))
            .then((environment) => {
                return environment.getEntry(id);
            })
            .then((entry) => entry.publish());

        console.log('published entry', id);

        return result;
    }

    static async unPublish(id: string) {
        const result = await client.manageClient
            .getSpace(GLOBAL_CONFIG.CONTENTFUL_SPACE_ID)
            .then((space) => space.getEnvironment(GLOBAL_CONFIG.environment))
            .then((environment) => {
                return environment.getEntry(id);
            })
            .then((entry) => entry.unpublish());

        console.log('unpublished entry', id);

        return result;
    }

    /**
     * Get entry from Contentful API
     *
     * @param contentType content type
     * @param entryId entry id
     */
    static async getEntry({
        contentType,
        entryId,
    }: {
        contentType?: ContentFulType;
        entryId: string;
    }): Promise<any> {
        let params = {};
        if (contentType) {
            params = {
                content_type: contentType,
            };
        }

        await rrlSchedule();

        return client.client.getEntry(entryId!, params);
    }

    static async getAssets(params) {
        await rrlSchedule();

        const { data: assets } = await contentfulApiRequest({
            url: `${GLOBAL_CONFIG.CONTENTFUL_API_PATH}/spaces/${GLOBAL_CONFIG.CONTENTFUL_SPACE_ID}/environments/${GLOBAL_CONFIG.environment}/assets`,
            method: 'GET',
            params: params || {},
        });

        console.log('get assets');
        return assets;
    }

    static async getAllAssets() {
        await rrlSchedule();

        const { data: assets } = await contentfulApiRequest({
            url: `${GLOBAL_CONFIG.CONTENTFUL_API_PATH}/spaces/${GLOBAL_CONFIG.CONTENTFUL_SPACE_ID}/environments/${GLOBAL_CONFIG.environment}/assets`,
            method: 'GET',
            params: {
                limit: 1000,
            },
        });

        return assets;
    }
}
