import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { ImageIcon, VideoPlaylistIcon } from '../../../assets/icons';
import {
    IMAGE_SIZE_MIN_HEIGHT,
    IMAGE_SIZE_MIN_WIDTH,
    LIMIT_DEFAULT_TEXT_LENGTH,
    LIMIT_DESCRIPTION_LENGTH,
    LIMIT_HEADLINE_LENGTH,
    EXTERNAL_LINK_SOURCE_PATERN,
    LIMIT_NAME_LENGTH,
} from '../../../common/constants';
import { AddTags } from '../../../components/AddTags';
import { Button } from '../../../components/Button';
import { ButtonGroup } from '../../../components/ButtonGroup';
import { FileUploader } from '../../../components/FileUploader';
import { FlexGrid } from '../../../components/FlexGrid';
import { HorizontalLine } from '../../../components/HorizontalLine';
import { Input } from '../../../components/Input';
import { PageHeader } from '../../../components/PageHeader';
import { RadioGroup } from '../../../components/RadioGroup';
import { Select } from '../../../components/Select';
import { Text } from '../../../components/Text';
import { ContentfulApiService } from '../../../services/ContentfulApi.service';
import { ContentfulTransformService } from '../../../services/ContentfulTransform.service';
import { updateModalAction } from '../../../store/store.actions';
import { COLORS } from '../../../styles/variables';
import { ArticleType } from '../../../types';
import {
    checkImageSize,
    getImageSize,
    getImageSizeBySource,
    getTagsIntersection,
    isNotRequireAndWhiteSpaces,
    isStringNotRequireAndWhiteSpaces,
    isStringRequiredMissing,
} from '../../../utils';
import { useDashboardArticleFolderHook } from '../../Dashboard/hooks/use-article-folder-hook';
import { CreateEditModal } from '../../../components/CreateEditModal';

const PreviewImage = styled(FlexGrid)`
    position: relative;
    min-height: 240px;
    overflow: hidden;
    border-radius: 5px;
    width: 100%;

    img {
        width: 100%;
        object-fit: cover;
    }
`;

const Form = styled(FlexGrid)`
    padding: 1em 2em;
    max-width: 500px;
    width: 100%;
    flex-direction: column;
    gap: 1em;
    margin-top: 0.5em;
`;

const Action = styled(FlexGrid)`
    border-top: 1px solid ${COLORS.lightGray};
    width: 100%;
    justify-content: space-between;
    padding: 1em 0em 1em 0em;
    margin-top: 2em;
    align-items: center;
`;

const LinkText = styled(Text)`
    max-width: 280px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const PreviewBtn = styled(Button)`
    flex-shrink: 0;
`;

interface Props {
    stepNavigator?: any;
    articleState: [
        ArticleType,
        React.Dispatch<React.SetStateAction<ArticleType>>
    ];
}

export const ArticleContent: React.FC<Props> = ({
    stepNavigator,
    articleState,
}) => {
    const dispatch = useDispatch();
    const [article, setArticle] = articleState;
    const { content } = article;
    const [showPreview, setShowPreview] = useState(false);
    const { appLanguages } = article.identifierDefinition;
    const [activeLanguage, setActiveLanguage] = useState(appLanguages[0]);

    const [folderName, setFolderName] = useState('');
    const [folderDescription, setFolderDescription] = useState('');
    const [createEditSuccess, setCreateEditSuccess] = useState(null);
    const [createArticleDisabled, setCreateArticleDisabled] = useState(false);
    const [apiUpdate, setApiUpdate] = useState(false);
    const [createModal, setCreateModal] = useState(false);
    const [imageSizeOk, setImageSizeOk] = useState(false);

    const [{ hookData: articleFolderHookData }] = useDashboardArticleFolderHook(
        { perPage: 100, page: 1 },
        [apiUpdate]
    );

    const articleFolders = ContentfulTransformService.getArticleFolders(
        articleFolderHookData?.items || []
    );

    useEffect(() => {
        const newArticle = { ...article };
        newArticle.identifierDefinition.appLanguages.forEach((language) => {
            if (!newArticle.content.headline[language]) {
                newArticle.content.headline[language] = '';
            }
            if (!newArticle.content.description[language]) {
                newArticle.content.description[language] = '';
            }
            if (!newArticle.content.actionText[language]) {
                newArticle.content.actionText[language] = '';
            }
            if (!newArticle.content.linkSource[language]) {
                newArticle.content.linkSource[language] = '';
            }
        });
        setArticle(newArticle);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const checkedLanguage = appLanguages.map(
        (language) =>
            // Boolean(
            //     content.headline[language] &&
            //         content.description[language] &&
            //         content.actionText[language]
            // )
            language === activeLanguage
    );

    const dataIncomplete =
        !content.image ||
        !imageSizeOk ||
        isNotRequireAndWhiteSpaces(content.headline) ||
        isNotRequireAndWhiteSpaces(content.description) ||
        isNotRequireAndWhiteSpaces(content.actionText) ||
        !content.folders.length;
    // isNotRequireAndWhiteSpaces(content.linkSource) ||

    useEffect(() => {
        if (content.oldImage) {
            setShowPreview(true);
        }
    }, []);

    console.log(
        '===content==article===',
        content,
        isNotRequireAndWhiteSpaces(content.headline)
    );

    useEffect(() => {
        if (content.image) {
            getImageSizeBySource(content.image.preview).then((imageSize) => {
                if (!checkImageSize(imageSize[0], imageSize[1])) {
                    setImageSizeOk(false);
                    dispatch(
                        updateModalAction({
                            open: true,
                            title: 'error',
                            content: `image size limit: width > ${IMAGE_SIZE_MIN_WIDTH} height > ${IMAGE_SIZE_MIN_HEIGHT}`,
                        })
                    );
                } else {
                    setImageSizeOk(true);
                }
            });
        }
    }, [content.image]);

    return (
        <FlexGrid width="100%" direction="column">
            <PageHeader
                title={
                    <Text font="OS" fontSize="1.375rem" uppercase>
                        Content
                    </Text>
                }
            />
            <Form>
                <FileUploader
                    fileType="image/*"
                    label="Upload Image"
                    info={{
                        title: 'Upload Image',
                        content:
                            'Upload Image short desc goes here. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt.',
                    }}
                    value={content.image}
                    onChange={(val) =>
                        setArticle({
                            ...article,
                            content: {
                                ...article.content,
                                image: val,
                                oldImage: false,
                            },
                        })
                    }
                />
                {content.image && !showPreview && (
                    <>
                        <FlexGrid width="100%" justifyContent="flex-end">
                            <Button
                                onClick={() => setShowPreview(true)}
                                negative
                                title="Show image"
                            />
                        </FlexGrid>
                        <HorizontalLine
                            m="0"
                            height="1px"
                            backgroundColor={COLORS.lightGray}
                        />
                    </>
                )}
                {content.image && showPreview && (
                    <>
                        <PreviewImage>
                            <img src={content.image.preview} alt="article" />
                        </PreviewImage>
                        <FlexGrid
                            width="100%"
                            justifyContent="space-between"
                            alignItems="center"
                            gap="1em"
                        >
                            <FlexGrid width="100%" gap="1em">
                                <ImageIcon color={COLORS.gray} />
                                <FlexGrid direction="column" gap="0.2em">
                                    <LinkText color={COLORS.green}>
                                        {content.image.name}
                                    </LinkText>
                                    <Text>
                                        {(content.image.size / 1e3).toFixed(2)}{' '}
                                        kb
                                    </Text>
                                </FlexGrid>
                            </FlexGrid>
                            <PreviewBtn
                                onClick={() => setShowPreview(false)}
                                negative
                                title="Hide image"
                            />
                        </FlexGrid>
                    </>
                )}
                {content.image && (
                    <>
                        <RadioGroup
                            label="Featured"
                            info={{
                                title: 'Featured Article',
                                content:
                                    'Article will display on Mobile APP Dashboard screen.',
                            }}
                            options={[
                                { label: 'Yes', value: 'Yes' },
                                { label: 'No', value: 'No' },
                            ]}
                            value={content.isFeatured ? 'Yes' : 'No'}
                            onChange={(val) => {
                                setArticle({
                                    ...article,
                                    content: {
                                        ...article.content,
                                        isFeatured: val === 'Yes',
                                    },
                                });
                            }}
                        />
                        <AddTags
                            value={content.tags}
                            onChange={(val) =>
                                setArticle({
                                    ...article,
                                    content: {
                                        ...article.content,
                                        tags: getTagsIntersection(
                                            content.tags || [],
                                            val
                                                .split(' ')
                                                .filter((el: string) => el)
                                        ),
                                    },
                                })
                            }
                        />
                    </>
                )}
                <ButtonGroup
                    titles={appLanguages}
                    info={{
                        title: 'Multiple Languages',
                        content:
                            'Please fill in all the different language sections according your choices.',
                    }}
                    active={activeLanguage}
                    onChange={setActiveLanguage}
                    checked={checkedLanguage}
                />
                <CreateEditModal
                    mode="create"
                    open={createModal}
                    onClose={() => {
                        setFolderName('');
                        setFolderDescription('');
                        setCreateModal(false);
                    }}
                    onSubmit={() => {
                        setCreateArticleDisabled(true);
                        ContentfulApiService.createContent('articleFolder', {
                            name: folderName,
                            description: folderDescription,
                        })
                            .then(() => {
                                setCreateEditSuccess(true);
                                setApiUpdate(!apiUpdate);
                            })
                            .finally(() => {
                                setCreateArticleDisabled(false);
                            });
                    }}
                    setSuccess={setCreateEditSuccess}
                    success={createEditSuccess}
                    title="Create New Folder"
                    disabled={
                        createArticleDisabled ||
                        folderName.trim() === '' ||
                        isStringNotRequireAndWhiteSpaces(folderDescription)
                    }
                    inputComponents={[
                        <Input
                            maxLength={LIMIT_NAME_LENGTH}
                            label="Folder Name"
                            value={folderName}
                            onChange={(val) => setFolderName(val)}
                        />,
                        <Input
                            maxLength={LIMIT_DESCRIPTION_LENGTH}
                            placeholder="Enter"
                            multiline
                            height="88px"
                            label="Description"
                            value={folderDescription}
                            onChange={(val) => setFolderDescription(val)}
                        />,
                    ]}
                />
                <Select
                    form
                    label="Folders"
                    placeholder="- Select Folders -"
                    info={{
                        title: 'Folders',
                        content:
                            'Add your article to one or more folders. Folders can help viewers discover your content faster',
                    }}
                    multiple
                    options={articleFolders}
                    optionsBottom={
                        <Button
                            onClick={() => setCreateModal(true)}
                            iconSlot="start"
                            negative
                            title="Create New Folder"
                            icon={<VideoPlaylistIcon />}
                        />
                    }
                    value={content.folders}
                    renderOption={(v) => {
                        return v.title;
                    }}
                    renderValue={() =>
                        content.folders[0]?.title || (
                            <Text fontSize="0.9rem" color={COLORS.gray}>
                                - Select Folders -
                            </Text>
                        )
                    }
                    onChange={(newVal) => {
                        setArticle({
                            ...article,
                            content: {
                                ...article.content,
                                folders: newVal,
                            },
                        });
                    }}
                />
                <Input
                    label="Headline"
                    value={content.headline[activeLanguage]}
                    maxLength={LIMIT_HEADLINE_LENGTH}
                    onChange={(val) =>
                        setArticle((state) => {
                            const newState = { ...state };
                            newState.content.headline[activeLanguage] = val;
                            return newState;
                        })
                    }
                />
                <Input
                    label="Description"
                    multiline
                    height="250px"
                    placeholder="Enter"
                    rows={10}
                    value={content.description[activeLanguage]}
                    maxLength={LIMIT_DESCRIPTION_LENGTH}
                    onChange={(val) =>
                        setArticle((state) => {
                            const newState = { ...state };
                            newState.content.description[activeLanguage] = val;
                            return newState;
                        })
                    }
                />
                <Input
                    label="Text to Display (Call to Action)"
                    info={{
                        title: 'Text to Display',
                        content: 'Text to Display',
                    }}
                    value={content.actionText[activeLanguage]}
                    maxLength={LIMIT_DEFAULT_TEXT_LENGTH}
                    onChange={(val) =>
                        setArticle((state) => {
                            const newState = { ...state };
                            newState.content.actionText[activeLanguage] = val;
                            return newState;
                        })
                    }
                />
                <HorizontalLine
                    backgroundColor={COLORS.lightGray}
                    height="1px"
                />
                <Input
                    label="Link Source"
                    maxLength={LIMIT_DEFAULT_TEXT_LENGTH}
                    info={{
                        title: 'Link Source',
                        content: 'Link Source',
                    }}
                    value={content.linkSource[activeLanguage]}
                    onChange={(val) =>
                        setArticle((state) => {
                            const newState = { ...state };
                            newState.content.linkSource[activeLanguage] = val;
                            return newState;
                        })
                    }
                />
                <Action>
                    <Button
                        negative
                        icon="back"
                        title="Back"
                        onClick={() =>
                            stepNavigator((prev: number) => prev - 1)
                        }
                    />
                    <Button
                        disabled={dataIncomplete}
                        p="1em 2em"
                        icon="next"
                        title="Next"
                        onClick={() => {
                            if (
                                !content.linkSource[activeLanguage]?.match(
                                    EXTERNAL_LINK_SOURCE_PATERN
                                )
                            ) {
                                dispatch(
                                    updateModalAction({
                                        open: true,
                                        title: 'error',
                                        content:
                                            'Please start the URL with https:// for a secure and valid connection ',
                                    })
                                );
                                return;
                            }

                            // if (
                            //     _.max(
                            //         _.map(
                            //             content.headline,
                            //             (v, k) => v?.length || 0
                            //         )
                            //     ) >= 30
                            // ) {
                            //     dispatch(
                            //         updateModalAction({
                            //             open: true,
                            //             title: 'error',
                            //             content:
                            //                 'headline lenght should less 30',
                            //         })
                            //     );
                            //     return;
                            // }

                            // Check multi-language fields are filled
                            for (const language of appLanguages) {
                                if (
                                    isStringRequiredMissing(
                                        content.headline[language]
                                    )
                                ) {
                                    dispatch(
                                        updateModalAction({
                                            open: true,
                                            title: 'error',
                                            content: `Headline in ${language} is missing`,
                                        })
                                    );
                                    return;
                                }
                                if (
                                    isStringRequiredMissing(
                                        content.description[language]
                                    )
                                ) {
                                    dispatch(
                                        updateModalAction({
                                            open: true,
                                            title: 'error',
                                            content: `Description in ${language} is missing`,
                                        })
                                    );
                                    return;
                                }
                                if (
                                    isStringRequiredMissing(
                                        content.actionText[language]
                                    )
                                ) {
                                    dispatch(
                                        updateModalAction({
                                            open: true,
                                            title: 'error',
                                            content: `Text to Display (Call to Action) in ${language} is missing`,
                                        })
                                    );
                                    return;
                                }
                                if (
                                    isStringRequiredMissing(
                                        content.linkSource[language]
                                    )
                                ) {
                                    dispatch(
                                        updateModalAction({
                                            open: true,
                                            title: 'error',
                                            content: `Link Source in ${language} is missing`,
                                        })
                                    );
                                    return;
                                }
                            }

                            stepNavigator((prev: number) => prev + 1);
                        }}
                    />
                </Action>
            </Form>
        </FlexGrid>
    );
};
