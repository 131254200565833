import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Button } from '../../../components/Button';
import { CheckBoxGroup } from '../../../components/CheckBoxGroup';
import { FlexGrid } from '../../../components/FlexGrid';
import { PageHeader } from '../../../components/PageHeader';
import { RadioGroup } from '../../../components/RadioGroup';
import { Text } from '../../../components/Text';
import { COLORS } from '../../../styles/variables';
import { DownloadTemplate } from '../../../components/DownloadTemplate';
import { PlusIcon } from '../../../assets/icons';
import { Input } from '../../../components/Input';
import { SearchSelect } from '../../../components/SearchSelect';
import { QuizType } from '../../../types';
import { DropDownPopulate } from '../../../services/Api.service';
import {
    LIMIT_UPLOAD_MOBILE,
    LIMIT_UPLOAD_PID,
    USER_TYPE_OPTIONS,
} from '../../../common/constants';
import { getSubSectorsByMapping } from '../../../utils';

const Form = styled(FlexGrid)`
    padding: 1em 2em;
    min-width: 500px;
    flex-direction: column;
    gap: 1em;
    margin-top: 0.5em;
`;

const Action = styled(FlexGrid)`
    border-top: 1px solid ${COLORS.lightGray};
    width: 100%;
    justify-content: space-between;
    padding: 1em 0em 1em 0em;
    margin-top: 2em;
    align-items: center;
`;

interface Props {
    dropdownPopulate?: DropDownPopulate;
    stepNavigator?: any;
    quizState: [
        QuizType,
        React.Dispatch<React.SetStateAction<QuizType>>
    ];
}

export const TargetUser: React.FC<Props> = ({
    stepNavigator,
    quizState,
    dropdownPopulate,
}) => {
    const [quiz, setQuiz] = quizState;
    const { targetUser } = quiz;

    const mechanicSelected = targetUser.userTypes.includes('Mechanics');
    const dealerSelected = targetUser.userTypes.includes('Dealers');
    const workshopSelected = targetUser.userTypes.includes('Workshop');

    const dataIncomplete = !targetUser.userTypes?.length;

    const getDealerSubSegments = useCallback(() => {
        return getSubSectorsByMapping(
            targetUser.dealerSegments,
            dropdownPopulate.subSectors
        );
    }, [dropdownPopulate.subSectors, targetUser.dealerSegments]);

    const getMechanicsSubSegments = useCallback(() => {
        return getSubSectorsByMapping(
            targetUser.mechanicsSegments,
            dropdownPopulate.subSectors
        );
    }, [dropdownPopulate.subSectors, targetUser.mechanicsSegments]);

    return (
        <FlexGrid width="100%" direction="column">
            <PageHeader
                title={
                    <Text font="OS" fontSize="1.375rem" uppercase>
                        Target User
                    </Text>
                }
            />
            <Form>
                <CheckBoxGroup
                    values={targetUser.userTypes}
                    onChange={(value: string[]) =>
                        setQuiz({
                            ...quiz,
                            targetUser: {
                                ...quiz.targetUser,
                                userTypes: value,
                            },
                        })
                    }
                    label="User Type"
                    info={{ title: '', content: '' }}
                    options={USER_TYPE_OPTIONS}
                />
                {mechanicSelected && (
                    <>
                        {/* <DownloadTemplate
                            title="Download Template"
                            buttonText="Upload Mobile"
                        />
                        <Input
                            value={targetUser.mobile}
                            maxLength={LIMIT_UPLOAD_MOBILE}
                            onChange={(value: string) =>
                                setQuiz({
                                    ...quiz,
                                    targetUser: {
                                        ...quiz.targetUser,
                                        mobile: value,
                                    },
                                })
                            }
                            label="Upload Mobile"
                            info={{
                                title: 'Upload Mobile for Mechanic',
                                content:
                                    'Short descriptions goes here, lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam.',
                            }}
                            placeholder="- Select File -"
                            icon={<PlusIcon />}
                            iconPosition="end"
                        /> */}
                        <CheckBoxGroup
                            values={targetUser.spaceOfMechanics}
                            onChange={(value: string[]) =>
                                setQuiz({
                                    ...quiz,
                                    targetUser: {
                                        ...quiz.targetUser,
                                        spaceOfMechanics: value,
                                    },
                                })
                            }
                            label="Space of Mechanics"
                            info={{
                                title: 'Space of Mechanic',
                                content:
                                    'Short descriptions goes here, lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam.',
                            }}
                            options={[
                                { label: 'MCO', value: 'MCO' },
                                { label: 'PCO', value: 'PCO' },
                                { label: 'CVO', value: 'CVO' },
                            ]}
                        />
                        <SearchSelect
                            value={targetUser.mechanicsSegments}
                            onChange={(value: string[]) =>
                                setQuiz({
                                    ...quiz,
                                    targetUser: {
                                        ...quiz.targetUser,
                                        mechanicsSegments: value,
                                    },
                                })
                            }
                            label="Mechanics Segment"
                            info={{
                                title: 'Mechanics Segment',
                                content:
                                    'Short descriptions goes here, lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam.',
                            }}
                            options={dropdownPopulate.sectors || []}
                        />
                        <SearchSelect
                            value={targetUser.mechanicsSubSegments}
                            onChange={(value: string[]) =>
                                setQuiz({
                                    ...quiz,
                                    targetUser: {
                                        ...quiz.targetUser,
                                        mechanicsSubSegments: value,
                                    },
                                })
                            }
                            label="Mechanics Sub Segment"
                            info={{
                                title: 'Mechanics Sub Segment',
                                content:
                                    'Short descriptions goes here, lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam.',
                            }}
                            options={getMechanicsSubSegments() || []}
                        />
                        {/* <RadioGroup
                            value={targetUser.connekt ? 'Yes' : 'No'}
                            onChange={(value: string) =>
                                setQuiz({
                                    ...quiz,
                                    targetUser: {
                                        ...quiz.targetUser,
                                        connekt: value === 'Yes',
                                    },
                                })
                            }
                            label="Connekt"
                            info={{ title: '', content: '' }}
                            options={[
                                { label: 'Yes', value: 'Yes' },
                                { label: 'No', value: 'No' },
                            ]}
                        /> */}
                    </>
                )}
                {(dealerSelected || workshopSelected) && (
                    <>
                        {/* <DownloadTemplate
                            title="Download Template"
                            buttonText="Upload PID"
                        />
                        <Input
                            value={targetUser.mobile}
                            onChange={(value: string) =>
                                setQuiz({
                                    ...quiz,
                                    targetUser: {
                                        ...quiz.targetUser,
                                        mobile: value,
                                    },
                                })
                            }
                            label="Upload PID"
                            info={{
                                title: 'Upload PID for Workshop or Dealer',
                                content:
                                    'Short descriptions goes here, lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam.',
                            }}
                            placeholder="- Select File -"
                            icon={<PlusIcon />}
                            iconPosition="end"
                        /> */}
                        <SearchSelect
                            value={targetUser.dealerSegments}
                            onChange={(value: string[]) =>
                                setQuiz({
                                    ...quiz,
                                    targetUser: {
                                        ...quiz.targetUser,
                                        dealerSegments: value,
                                    },
                                })
                            }
                            label="Dealer/WS Segment"
                            info={{
                                title: 'Dealer/WS Segment',
                                content:
                                    'Short descriptions goes here, lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam.',
                            }}
                            options={dropdownPopulate.sectors || []}
                        />
                        <SearchSelect
                            value={targetUser.dealerSubSegments}
                            onChange={(value: string[]) =>
                                setQuiz({
                                    ...quiz,
                                    targetUser: {
                                        ...quiz.targetUser,
                                        dealerSubSegments: value,
                                    },
                                })
                            }
                            label="Dealer/WS Sub Segment"
                            info={{
                                title: 'Dealer/WS Sub Segment',
                                content:
                                    'Short descriptions goes here, lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam.',
                            }}
                            options={getDealerSubSegments() || []}
                        />
                    </>
                )}

                <Action>
                    <Button
                        negative
                        icon="back"
                        title="Back"
                        onClick={() =>
                            stepNavigator((prev: number) => prev - 1)
                        }
                    />
                    <Button
                        disabled={dataIncomplete}
                        p="1em 2em"
                        icon="next"
                        title="Next"
                        onClick={() =>
                            stepNavigator((prev: number) => prev + 1)
                        }
                    />
                </Action>
            </Form>
        </FlexGrid>
    );
};
