import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { BonusPointsIcon } from '../../../assets/icons';
import { Button } from '../../../components/Button';
import { FlexGrid } from '../../../components/FlexGrid';
import { PageHeader } from '../../../components/PageHeader';
import { PointsPickerInput } from '../../../components/PointsPickerInput';
import { Text } from '../../../components/Text';
import { updateModalAction } from '../../../store/store.actions';
import { COLORS } from '../../../styles/variables';
import { RegistrationContestType } from '../../../types';
import {
    ContentStatus,
    composeDate,
    getContentStatus,
    isStringRequiredMissing,
} from '../../../utils';
import { ButtonGroup } from '../../../components/ButtonGroup';
import { Input } from '../../../components/Input';
import { LIMIT_HEADLINE_LENGTH } from '../../../common/constants';
import { HorizontalLine } from '../../../components/HorizontalLine';
import AccountMultipleUsers from '../../../assets/icons/account-multiple-users';

const Form = styled(FlexGrid)`
    padding: 1em 2em;
    max-width: 500px;
    width: 100%;
    flex-direction: column;
    gap: 1em;
    margin-top: 0.5em;
`;

const Action = styled(FlexGrid)`
    border-top: 1px solid ${COLORS.lightGray};
    width: 100%;
    justify-content: space-between;
    padding: 1em 0em 1em 0em;
    margin-top: 2em;
    align-items: center;
`;

interface Props {
    stepNavigator?: any;
    registrationContestState: [
        RegistrationContestType,
        React.Dispatch<React.SetStateAction<RegistrationContestType>>
    ];
    initRegistrationContestData: RegistrationContestType;
}

export const RegistrationContestDefintion: React.FC<Props> = ({
    stepNavigator,
    registrationContestState,
    initRegistrationContestData,
}) => {
    const dispatch = useDispatch();
    const [scanningContest, setScanningContest] = registrationContestState;
    const { content } = scanningContest;

    const { appLanguages } = scanningContest.identifierDefinition;
    const [activeLanguage, setActiveLanguage] = useState(appLanguages[0]);

    useEffect(() => {
        const newScanningContest = { ...scanningContest };
        newScanningContest.identifierDefinition.appLanguages.forEach(
            (language) => {
                if (!newScanningContest.content.headline[language]) {
                    newScanningContest.content.headline[language] = '';
                }
            }
        );
        setScanningContest(newScanningContest);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const scanningContestStatus = getContentStatus(
        initRegistrationContestData,
        composeDate(
            initRegistrationContestData?.identifierDefinition.startDate,
            initRegistrationContestData?.identifierDefinition.startTime
        )?.toDate(),
        composeDate(
            initRegistrationContestData?.identifierDefinition.endDate,
            initRegistrationContestData?.identifierDefinition.endDate
        )?.toDate()
    );

    const isContestPublished =
        scanningContestStatus === ContentStatus.LIVE ||
        scanningContestStatus === ContentStatus.EXPIRED;

    useEffect(() => {
        const newScanningContest = { ...scanningContest };
        newScanningContest.identifierDefinition.appLanguages.forEach(
            (language) => {
                if (!newScanningContest.content.headline[language]) {
                    newScanningContest.content.headline[language] = '';
                }
            }
        );
        setScanningContest(newScanningContest);
    }, []);

    const dataIncomplete =
        content.targetedUsers === 0 || content.pointsForAchievement === 0;

    const checkedLanguage = appLanguages.map(
        (language) => language === activeLanguage
    );

    return (
        <FlexGrid width="100%" direction="column">
            <PageHeader
                title={
                    <Text font="OS" fontSize="1.375rem" uppercase>
                        Contest Definition
                    </Text>
                }
            />
            <Form>
                <ButtonGroup
                    titles={appLanguages}
                    info={{
                        title: 'Multiple Languages',
                        content:
                            'Please fill in all the different language sections according your choices.',
                    }}
                    active={activeLanguage}
                    onChange={setActiveLanguage}
                    checked={checkedLanguage}
                />
                <Input
                    label="Headline"
                    value={content.headline[activeLanguage]}
                    maxLength={LIMIT_HEADLINE_LENGTH}
                    onChange={(val) =>
                        setScanningContest((state) => {
                            const newState = { ...state };
                            newState.content.headline[activeLanguage] = val;
                            return newState;
                        })
                    }
                    disabled={isContestPublished}
                />
                <HorizontalLine
                    backgroundColor={COLORS.lightGray}
                    height="1px"
                />

                <FlexGrid
                    direction="column"
                    flex="1"
                    width="100%"
                    p="0em 2em 0em 1em"
                >
                    <FlexGrid width="100%" gap="0.5em">
                        <PointsPickerInput
                            label="Targeted Users"
                            width="5rem"
                            explicitPlaceHolder="Users"
                            icon={<AccountMultipleUsers />}
                            maxPoints={9999}
                            info={{
                                title: 'Targeted Users',
                                content: 'You can add Targeted Users',
                            }}
                            value={content.targetedUsers}
                            onChange={(val) => {
                                setScanningContest((state) => {
                                    const newState = { ...state };
                                    newState.content.targetedUsers = val;
                                    newState.content.reachedUsers = val;
                                    return newState;
                                });
                            }}
                            disabled={isContestPublished}
                        />
                        <PointsPickerInput
                            label="Points for Achievement"
                            width="5rem"
                            explicitPlaceHolder="pts"
                            icon={<BonusPointsIcon />}
                            maxPoints={9999}
                            info={{
                                title: 'Points for Achievement',
                                content: 'You can add Points for Achievement',
                            }}
                            value={content.pointsForAchievement}
                            onChange={(val) => {
                                setScanningContest((state) => {
                                    const newState = { ...state };
                                    newState.content.pointsForAchievement = val;
                                    return newState;
                                });
                            }}
                            disabled={isContestPublished}
                        />
                    </FlexGrid>
                </FlexGrid>

                <Action>
                    <Button
                        negative
                        icon="back"
                        title="Back"
                        onClick={() =>
                            stepNavigator((prev: number) => prev - 1)
                        }
                    />
                    <Button
                        disabled={dataIncomplete}
                        p="1em 2em"
                        icon="next"
                        title="Next"
                        onClick={() => {
                            // Check multi-language fields are filled
                            for (const language of appLanguages) {
                                if (
                                    isStringRequiredMissing(
                                        content.headline[language]
                                    )
                                ) {
                                    dispatch(
                                        updateModalAction({
                                            open: true,
                                            title: 'error',
                                            content: `Headline in ${language} is missing`,
                                        })
                                    );
                                    return;
                                }
                            }

                            stepNavigator((prev: number) => prev + 1);
                        }}
                    />
                </Action>
            </Form>
        </FlexGrid>
    );
};
