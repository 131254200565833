import React from 'react';
import styled from 'styled-components';
import { Button } from '../../../components/Button';
import { FlexGrid } from '../../../components/FlexGrid';
import { PageHeader } from '../../../components/PageHeader';
import { SearchSelect } from '../../../components/SearchSelect';
import { Text } from '../../../components/Text';
import { COLORS } from '../../../styles/variables';
import { RegistrationContestType } from '../../../types';
import { DropDownPopulate } from '../../../services/Api.service';
import { CheckBoxGroup } from '../../../components/CheckBoxGroup';
import { UK_OPTIONS } from '../../../common/constants';
import { getCountry } from '../../../utils';

const Form = styled(FlexGrid)`
    padding: 1em 2em;
    min-width: 500px;
    flex-direction: column;
    gap: 1em;
    margin-top: 0.5em;
`;

const Action = styled(FlexGrid)`
    border-top: 1px solid ${COLORS.lightGray};
    width: 100%;
    justify-content: space-between;
    padding: 1em 0em 1em 0em;
    margin-top: 2em;
    align-items: center;
`;

interface Props {
    dropdownPopulate?: DropDownPopulate;
    stepNavigator?: any;
    registrationContestState: [
        RegistrationContestType,
        React.Dispatch<React.SetStateAction<RegistrationContestType>>
    ];
}

export const TargetGeography: React.FC<Props> = ({
    dropdownPopulate,
    stepNavigator,
    registrationContestState,
}) => {
    const [scanningContest, setScanningContest] = registrationContestState;
    const { targetGeography } = scanningContest;

    // TODO
    const hideTargetGeography = true;

    const dataIncomplete = !targetGeography.regions?.length;
    const isIrealandUk =
        getCountry() === 'Ireland' || getCountry() === 'Ireland/UK';

    return (
        <FlexGrid width="100%" direction="column">
            <PageHeader
                title={
                    <Text font="OS" fontSize="1.375rem" uppercase>
                        Target Geography
                    </Text>
                }
            />
            <Form>
                {isIrealandUk && (
                    <CheckBoxGroup
                        values={targetGeography.regions}
                        onChange={(val: string[]) =>
                            setScanningContest({
                                ...scanningContest,
                                targetGeography: {
                                    ...scanningContest.targetGeography,
                                    regions: val,
                                },
                            })
                        }
                        label="Select Country"
                        info={{
                            title: 'Select Country',
                            content: 'Select Country',
                        }}
                        options={UK_OPTIONS}
                    />
                )}
                {!hideTargetGeography && (
                    <SearchSelect
                        value={targetGeography.regions}
                        onChange={(val) =>
                            setScanningContest({
                                ...scanningContest,
                                targetGeography: {
                                    ...scanningContest.targetGeography,
                                    regions: val,
                                },
                            })
                        }
                        options={dropdownPopulate.regions || []}
                        label="Region"
                        info={{
                            title: 'Region',
                            content: 'Region',
                        }}
                    />
                )}
                <Action>
                    <Button
                        negative
                        icon="back"
                        title="Back"
                        onClick={() =>
                            stepNavigator((prev: number) => prev - 1)
                        }
                    />
                    <Button
                        disabled={
                            isIrealandUk
                                ? dataIncomplete
                                : !hideTargetGeography && dataIncomplete
                        }
                        p="1em 2em"
                        icon="next"
                        title="Next"
                        onClick={() =>
                            stepNavigator((prev: number) => prev + 1)
                        }
                    />
                </Action>
            </Form>
        </FlexGrid>
    );
};
